import { useState } from 'react';
import './style.scss';

const IncomingTable = ({ incoming }) => {
  const [showTitle, setShowTitle] = useState(null);
  const [showStatus, setShowStatus] = useState(null);
  return (
    <div className="incoming-table">
      <table className="incoming-table-content">
        <thead>
          <tr className="incoming-table-content__theader-tr">
            <th className="incoming-table-content__theader-th">Estipulante</th>
            <th className="incoming-table-content__theader-th">Contrato</th>
            <th className="incoming-table-content__theader-th">Produto</th>
            <th className="incoming-table-content__theader-th">
              Número apólice
            </th>
            <th className="incoming-table-content__theader-th">Vitalício</th>
            <th className="incoming-table-content__theader-th">Agenciamento</th>
            <th className="incoming-table-content__theader-th">Fatura</th>
          </tr>
        </thead>
        <tbody>
          {incoming.map((policyOwner) => {
            return policyOwner.contratos.map((contract) => (
              <tr
                className="incoming-table-content__tbody-tr"
                key={policyOwner.razao_social}
              >
                <td
                  className="incoming-table-content__tbody-td td-truncate"
                  key={contract.id}
                  onMouseEnter={() => {
                    setShowTitle(contract.id);
                  }}
                  onMouseLeave={() => setShowTitle(null)}
                >
                  {policyOwner.razao_social}
                  {showTitle === contract.id && (
                    <div className="incoming-table-content__title-pop-up">
                      {policyOwner.razao_social}
                    </div>
                  )}
                </td>
                <td className="incoming-table-content__tbody-td">
                  {contract.nome}
                </td>
                <td className="incoming-table-content__tbody-td">
                  {contract.produto_id === 1 ? 'Saúde' : 'Dental'}
                </td>
                <td
                  className="incoming-table-content__tbody-td"
                  onMouseEnter={() => {
                    setShowStatus(contract.id);
                  }}
                  onMouseLeave={() => setShowStatus(null)}
                >
                  {contract.numero_apolice}
                  {showStatus === contract.id && (
                    <div
                      className={`incoming-table-content__status-pop-up incoming-table-content__status-pop-up--${
                        contract.status === 'Ativo' ? 'active' : 'inactive'
                      }`}
                    >
                      {contract.status}
                    </div>
                  )}
                </td>
                <td className="incoming-table-content__tbody-td">
                  {contract.comissionamento[1].valorTotalVitalicio}
                </td>
                <td className="incoming-table-content__tbody-td">
                  {contract.comissionamento[1].valorTotalAgenciamento}
                </td>
                <td className="incoming-table-content__tbody-td">
                  {contract.comissionamento[1].valorTotalFatura}
                </td>
              </tr>
            ));
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IncomingTable;
