import { useState } from 'react';
import './style.scss';

const Top10PoliciesOwnersLifetime = ({ lifetimePolicyOwners }) => {
  const [showTitle, setShowTitle] = useState(null);

  return (
    <div className="top-10-lifetime-table">
      <div className="top-10-lifetime-table__header">
        <span className="top-10-lifetime-table__title">
          10 maiores vitalícios
        </span>
        <span className="top-10-lifetime-table__subtitle">últimos 3 meses</span>
      </div>
      <div className="top-10-lifetime-table__content">
        {lifetimePolicyOwners?.map((item) => (
          <div
            className="top-10-lifetime-table__content-item"
            key={item.razao_social}
          >
            <span
              className="top-10-lifetime-table__label label-truncate"
              onMouseEnter={() => {
                setShowTitle(item.razao_social);
              }}
              onMouseLeave={() => setShowTitle(null)}
            >
              {item.razao_social}
              {showTitle === item.razao_social && (
                <div className="top-10-lifetime-table__title-pop-up">
                  {item.razao_social}
                </div>
              )}
            </span>
            <span className="top-10-lifetime-table__value">
              {item.vitalicioFormatado}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Top10PoliciesOwnersLifetime;
