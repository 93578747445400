import { useState, useRef, useEffect } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import PhotoIcon from '@material-ui/icons/Photo';
import EmailIcon from '@material-ui/icons/Email';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Description from '@material-ui/icons/Description';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';
import { ContentWrapper, DropdownHeaderMenu } from './styles';
import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Modal from '../ModalEditLogoCorretor';
import ModalEmail from '../ModalEmailCorretor';
import ModalCreateCorretor from '../ModalCreateCorretor';
import DrawerProdutos from './ProdutosDrawer';
import { LuMailQuestion } from 'react-icons/lu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: '999'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  dropdown: {
    left: '-20px !important'
  }
}));

const MenuListComposition = (props) => {
  const posthog = usePostHog();
  const classes = useStyles();
  const user = props.user;
  const location = useLocation();
  const moduleUrl = location?.pathname?.split('/')[1];

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [openModalAddCorretor, setOpenModalAddCorretor] = useState(false);
  const [stateCnpj, setStateCnpj] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    posthog?.capture('Clicou em "3 pontos verticais" (Header)');
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (event && event.type === 'keydown') {
      return;
    }
    setOpenDrawer(!open);
  };

  const content = () => (
    <div
      className={classes.content}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <DrawerProdutos onClose={setOpenDrawer} />
    </div>
  );

  const handleOpenDrawerAdicionarBeneficiario = () => {
    posthog?.capture('Clicou em "Adicionar Beneficiário" (Header)');
    props?.setOpenAdicionarBeneficiario(true);
    setOpen(false);
  };

  const handleOpenDrawerAdicionarPlanilha = () => {
    posthog?.capture('Clicou em "Adicionar Planilha" (Header)');
    props?.setOpenAdicionarPlanilha(true);
    setOpen(false);
  };

  const handleOpenDrawerRequestInclusion = () => {
    posthog?.capture('Clicou em "RequestInclusion" (Header)');
    props?.setOpenRequestInclusion(true);
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
    const { cnpj } = JSON.parse(localStorage?.getItem('jwt_token_user'));
    setStateCnpj(cnpj);
  }, [open]);

  return (
    <div className={classes.root}>
      <div className="menu-top">
        {user.email === 'rodrigo.pedroni@innoaseguros.com.br' ? (
          <MoreVertIcon
            ref={anchorRef}
            onClick={handleToggle}
            className="vertical-points-button"
          />
        ) : (
          <MoreVertIcon
            ref={anchorRef}
            onClick={handleToggle}
            className="vertical-points-button"
          />
        )}

        <Popper
          className={classes.dropdown}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <ContentWrapper>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {user.email === 'rodrigo.pedroni@innoaseguros.com.br' ? (
                        <>
                          <DropdownHeaderMenu>
                            {moduleUrl !== 'corretor' ? (
                              <div>
                                <MenuItem
                                  onClick={
                                    handleOpenDrawerAdicionarBeneficiario
                                  }
                                >
                                  <ListItemIcon className="list-icon">
                                    <PersonAddIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar Beneficiário
                                  </Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={handleOpenDrawerAdicionarPlanilha}
                                >
                                  <ListItemIcon className="list-icon">
                                    <img
                                      src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
                                      alt="microsoft-excel-2019--v1"
                                    />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar Planilha
                                  </Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={handleOpenDrawerRequestInclusion}
                                >
                                  <ListItemIcon className="list-icon request-icon">
                                    <LuMailQuestion fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Link de Solicitação
                                  </Typography>
                                </MenuItem>
                              </div>
                            ) : (
                              ''
                            )}
                            {moduleUrl !== 'rh' ? (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    setOpenModal(true);
                                  }}
                                >
                                  <ListItemIcon className={classes.icon}>
                                    <PhotoIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar Logo
                                  </Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={() => {
                                    setOpenModalEmail(true);
                                  }}
                                >
                                  <ListItemIcon className={classes.icon}>
                                    <EmailIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar email para tickets
                                  </Typography>
                                </MenuItem>

                                {stateCnpj && (
                                  <MenuItem
                                    onClick={() => {
                                      setOpenModalAddCorretor(true);
                                    }}
                                  >
                                    <ListItemIcon className={classes.icon}>
                                      <SupervisedUserCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                      Adicionar corretor
                                    </Typography>
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={() => {
                                    setOpenDrawer(true);
                                  }}
                                >
                                  <ListItemIcon className={classes.icon}>
                                    <Description fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Configurar apresentação da corretora
                                  </Typography>
                                </MenuItem>
                              </>
                            ) : (
                              ''
                            )}
                          </DropdownHeaderMenu>
                        </>
                      ) : (
                        <>
                          <DropdownHeaderMenu>
                            {moduleUrl !== 'corretor' ? (
                              <div>
                                <MenuItem
                                  onClick={
                                    handleOpenDrawerAdicionarBeneficiario
                                  }
                                >
                                  <ListItemIcon className="list-icon">
                                    <PersonAddIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar Beneficiário
                                  </Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={handleOpenDrawerAdicionarPlanilha}
                                >
                                  <ListItemIcon className="list-icon">
                                    <img
                                      src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
                                      alt="microsoft-excel-2019--v1"
                                    />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar Planilha
                                  </Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={handleOpenDrawerRequestInclusion}
                                >
                                  <ListItemIcon className="list-icon request-icon">
                                    <LuMailQuestion fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Link de Solicitação
                                  </Typography>
                                </MenuItem>
                              </div>
                            ) : (
                              ''
                            )}
                            {moduleUrl !== 'rh' ? (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    setOpenModal(true);
                                  }}
                                >
                                  <ListItemIcon className={classes.icon}>
                                    <PhotoIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar Logo
                                  </Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={() => {
                                    setOpenModalEmail(true);
                                  }}
                                >
                                  <ListItemIcon className={classes.icon}>
                                    <EmailIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Adicionar email para tickets
                                  </Typography>
                                </MenuItem>

                                {stateCnpj && (
                                  <MenuItem
                                    onClick={() => {
                                      setOpenModalAddCorretor(true);
                                    }}
                                  >
                                    <ListItemIcon className={classes.icon}>
                                      <SupervisedUserCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                      Adicionar corretor
                                    </Typography>
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={() => {
                                    setOpenDrawer(true);
                                  }}
                                >
                                  <ListItemIcon className={classes.icon}>
                                    <Description fontSize="small" />
                                  </ListItemIcon>
                                  <Typography variant="inherit" noWrap>
                                    Configurar apresentação da corretora
                                  </Typography>
                                </MenuItem>
                              </>
                            ) : (
                              ''
                            )}
                          </DropdownHeaderMenu>
                        </>
                      )}
                    </MenuList>
                  </ContentWrapper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Modal openModal={openModal} setOpenModal={setOpenModal} />
        <ModalEmail
          openModalEmail={openModalEmail}
          setOpenModalEmail={setOpenModalEmail}
        />
        <ModalCreateCorretor
          openModalAddCorretor={openModalAddCorretor}
          setOpenModalAddCorretor={setOpenModalAddCorretor}
        />
        <SwipeableDrawer
          ModalProps={{
            BackdropProps: {
              classes: {
                root: classes.BackdropProps
              }
            }
          }}
          anchor="right"
          open={openDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {content()}
        </SwipeableDrawer>
      </div>
    </div>
  );
};

export default MenuListComposition;
