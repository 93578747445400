import user from './user';
import policyOwner from './policyOwner';
import tickets from './tickets';
import beneficiaries from './beneficiaries';
import insuranceCompanies from './insuranceCompanies';
import concierge from './concierge';
import moviment from './movement';
import business from './business';
import activities from './activities';
import tasks from './tasks';
import brokerProducts from './brokerProducts';
import reports from './reports';
import contracts from './contracts';
import billing from './billing';
import financial from './financial';

const modules = {
  user,
  policyOwner,
  tickets,
  beneficiaries,
  insuranceCompanies,
  concierge,
  moviment,
  business,
  activities,
  tasks,
  brokerProducts,
  reports,
  contracts,
  billing,
  financial
};

export default modules;
