import ReactDatePicker from 'react-datepicker';
import IncomingTable from './IncomingTable';
import './style.scss';

const IncomingInformation = ({
  policyOwnerList,
  policyOwnerId,
  setPolicyOwnerId,
  incomingInitialDate,
  setIncomingInitialDate,
  incomingFinalDate,
  setIncomingFinalDate,
  incomingMessage,
  incoming
}) => {
  return (
    <div className="incoming-information">
      <div className="incoming-information__header">
        <h2 className="incoming-information__header--title">
          Receita por estipulante
        </h2>
      </div>
      <div className="incoming-information-filter">
        <div className="incoming-information-filter__content">
          <label className="incoming-information-filter__label">
            Filtrar por empresa:
          </label>
          <select
            className="incoming-information-filter__select"
            value={policyOwnerId}
            onChange={(e) => setPolicyOwnerId(e.target.value)}
          >
            <option value="" disabled>
              Selecione...
            </option>
            {policyOwnerList?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.option}
              </option>
            ))}
          </select>
        </div>
        <div className="incoming-information-filter__content">
          <label className="incoming-information-filter__label">
            Filtrar por período:
          </label>
          <div className="incoming-information-filter__period">
            <div>
              <ReactDatePicker
                className="incoming-information-filter__input-date"
                selected={incomingInitialDate}
                locale="ptBR"
                onChange={(date) => {
                  setIncomingInitialDate(date);
                  if (!incomingFinalDate) {
                    setIncomingFinalDate(date);
                  }
                }}
                placeholderText="Data inicial"
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div>
              <ReactDatePicker
                className="incoming-information-filter__input-date"
                selected={incomingFinalDate}
                disabled={!incomingInitialDate}
                locale="ptBR"
                onChange={(date) => setIncomingFinalDate(date)}
                placeholderText="Data final"
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
      {incoming?.length > 0 ? (
        <IncomingTable incoming={incoming} />
      ) : (
        <span>{incomingMessage}</span>
      )}
    </div>
  );
};

export default IncomingInformation;
