import React from 'react';
import { Dot } from 'recharts';

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload, data } = this.props;
    const mes = data[payload.value]?.month;
    const ano = data[payload.value]?.date?.slice(0, 4);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-25)"
          style={{ fontSize: 10, textAlign: 'right' }}
        >
          {mes}
        </text>
        <text
          x={0}
          y={0}
          dy={26}
          textAnchor="end"
          fill="#666"
          transform="rotate(-25)"
          style={{ fontSize: 10, textAlign: 'right' }}
        >
          {ano}
        </text>
      </g>
    );
  }
}

class CustomizedShape extends React.PureComponent {
  render() {
    const { cx, cy, color } = this.props;
    return <Dot cx={cx} cy={cy} r={4} fill={color} />;
  }
}

class CustomizedTooltip extends React.PureComponent {
  render() {
    const {
      payload: [data],
      active
    } = this.props;
    if (active) {
      const {
        payload: { month, date, faturamento, vitalicio, agenciamento }
      } = data;
      const ano = date?.slice(0, 4);

      if (faturamento) {
        return (
          <div className="financial-graphic-tooltip">
            <div className="financial-graphic-tooltip__container financial-graphic-tooltip__container--prize">
              <div>
                <span className="financial-graphic-tooltip__title">{`${month} / ${ano}`}</span>
              </div>
              <div className="financial-graphic-tooltip__data-row">
                <label className="financial-graphic-tooltip__label">
                  Prêmio:{' '}
                </label>
                <p className="financial-graphic-tooltip__value">{`R$ ${faturamento.toLocaleString(
                  'pt-BR'
                )}`}</p>
              </div>
            </div>
          </div>
        );
      }

      if (vitalicio) {
        return (
          <div className="financial-graphic-tooltip">
            <div className="financial-graphic-tooltip__container financial-graphic-tooltip__container--lifetime">
              <div>
                <span className="financial-graphic-tooltip__title">{`${month} / ${ano}`}</span>
              </div>
              <div className="financial-graphic-tooltip__data-row">
                <label className="financial-graphic-tooltip__label">
                  Vitalício:{' '}
                </label>
                <p className="financial-graphic-tooltip__value">{`R$ ${vitalicio.toLocaleString(
                  'pt-BR'
                )}`}</p>
              </div>
            </div>
          </div>
        );
      }

      if (agenciamento) {
        return (
          <div className="financial-graphic-tooltip">
            <div className="financial-graphic-tooltip__container financial-graphic-tooltip__container--agency">
              <div>
                <span className="financial-graphic-tooltip__title">{`${month} / ${ano}`}</span>
              </div>
              <div className="financial-graphic-tooltip__data-row">
                <label className="financial-graphic-tooltip__label">
                  Agenciamento:{' '}
                </label>
                <p className="financial-graphic-tooltip__value">{`R$ ${agenciamento.toLocaleString(
                  'pt-BR'
                )}`}</p>
              </div>
            </div>
          </div>
        );
      }
    }

    return null;
  }
}

export { CustomizedAxisTick, CustomizedShape, CustomizedTooltip };
