import { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { BsArrowLeftShort } from 'react-icons/bs';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { HiLightBulb, HiTrash } from 'react-icons/hi';
import UpdatePopper from '../UpdatePopper/UpdatePopper';
import { useUser } from 'contexts/user';
import services from 'apps/broker/services';
import './style.scss';
import Button from 'components/DS/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& > * + *': { marginLeft: theme.spacing(2) }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff'
  },
  load: { color: '#6e3ec1' }
}));

registerLocale('ptBR', ptBR);

const PRODUTO_ID = 2;
const CONFIRM = 'ok';
const INSURANCE_TYPE = 'SeguroSaude';

const SeguroSaudeAtualizacao = ({
  estipulante,
  subs,
  setTab,
  getDataDrawer,
  contrato,
  setEditContract
}) => {
  const { user } = useUser();
  const classes = useStyles();

  const [loadApi, setLoadApi] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    plano: {},
    index: undefined,
    type: ''
  });
  const [seguradoras, setSeguradoras] = useState([]);

  const getCaracteristicas = (caracteristica) => {
    let status = false;
    contrato.caracteristicas &&
      contrato.caracteristicas.map((element, index) => {
        if (element.caracteristica === caracteristica) status = true;
        return '';
      });
    return status;
  };

  const getCopartipacaoDetails = () => {
    let status = false;
    if (contrato.caracteristicas && contrato.caracteristicas[1]) {
      status = contrato.caracteristicas[1].coparticipacao_details;
    }
    return status;
  };

  const getCusteioDetails = () => {
    let status = false;
    if (contrato.caracteristicas && contrato.caracteristicas[0]) {
      status = contrato.caracteristicas[0].custeio_details;
    }
    return status;
  };

  const getMultaDetails = () => {
    let status = false;
    if (contrato.caracteristicas && contrato.caracteristicas[0]) {
      status = contrato.caracteristicas[0].multa_details;
    }
    return status;
  };

  const getNumeroDeApolice = () => {
    let status = false;
    if (contrato && contrato.numero_apolice) {
      status = contrato.numero_apolice;
    }
    return status;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      planos:
        contrato.contrato_tipos.length > 0
          ? contrato.contrato_tipos.sort((a, b) =>
              a?.tipo?.localeCompare(b?.tipo)
            )
          : [{ nome: '' }],
      'possui-coparticipacao': getCaracteristicas('Coparticipação')
        ? 'sim'
        : 'não',
      'coparticipacao-details': getCopartipacaoDetails()
        ? getCopartipacaoDetails()
        : '',
      'custeio-details': getCusteioDetails() ? getCusteioDetails() : '',
      'multa-details': getMultaDetails() ? getMultaDetails() : '',
      'numero-apolice': getNumeroDeApolice() ? getNumeroDeApolice() : '',
      tipoCusto: getCaracteristicas('Custo Médio')
        ? 'Custo Médio'
        : 'Faixa Etária'
    }
  });

  const {
    fields: planosField,
    append: planosAppend,
    remove: planosRemove,
    update: planosUpdate
  } = useFieldArray({ control, name: 'planos' });

  const onSubmit = (data) => {
    setLoadApi(true);

    if (data.seguradora === '')
      data.seguradora = contrato.produto_seguradora_id;

    const body = { ...data, subsestipulantes: [...subs] };
    const { idref_contrato } = contrato;

    services.insuranceCompanies
      .updateContractInfoByContractIdref(
        PRODUTO_ID,
        idref_contrato,
        estipulante,
        CONFIRM,
        body
      )
      .then((resp) => {
        // console.log(resp.data)
        setEditContract(null);
        getDataDrawer();
        setEditContract(null);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadApi(false);
      });
  };

  const getSeguradora = () => {
    services.insuranceCompanies
      .getInsuranceCompanyByType(INSURANCE_TYPE)
      .then((resp) => {
        setSeguradoras(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openPopperUpdatePlan = (plano, index, type) => {
    setSelectedPlan({
      plano,
      index,
      type
    });

    setOpenPopper(true);
  };

  const deactivatePlan = (plano, index) => {
    const body = {
      contrato_precos_id: plano?.contrato_precos_id,
      contrato_id: plano?.contrato_id,
      tipo_plano: plano?.tipo
    };

    const resp = services.insuranceCompanies
      .updateDisablePlanByContractId(body)
      .then((resp) => {
        planosUpdate(index, {
          ...plano,
          deleted: 1
        });
        getDataDrawer();

        return resp.data;
      })
      .catch((error) => {
        return error.response.data;
      });

    return resp;
  };

  const activatePlan = (plano, index) => {
    const body = {
      contrato_precos_id: plano?.contrato_precos_id,
      contrato_id: plano?.contrato_id,
      tipo_plano: plano?.tipo
    };

    const resp = services.insuranceCompanies
      .updateEnablePlanByContractId(body)
      .then((resp) => {
        planosUpdate(index, {
          ...plano,
          deleted: 0
        });
        getDataDrawer();

        return resp.data;
      })
      .catch((error) => {
        return error.response.data;
      });

    return resp;
  };

  const removePlan = (plano, index) => {
    const body = {
      contrato_precos_id: plano?.contrato_precos_id,
      contrato_id: plano?.contrato_id,
      tipo_plano: plano?.tipo
    };

    const resp = services.insuranceCompanies
      .removePlanByContractId(body)
      .then((resp) => {
        planosRemove(index);
        getDataDrawer();

        return resp.data;
      })
      .catch((error) => {
        return error.response.data;
      });

    return resp;
  };

  useEffect(() => {
    getSeguradora();
  }, []);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loadApi}
        onClick={() => setLoadApi(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="health-update">
        <form
          className="health-update-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="health-update-back">
            <button
              className="health-update-back__button"
              onClick={() => setEditContract(null)}
            >
              <BsArrowLeftShort size={25} />
              <span className="health-update-back__span">Voltar</span>
            </button>
          </div>
          <div className="health-update-title">
            <span className="health-update-title__span">
              Atualização de Benefício
            </span>
          </div>
          <div className="health-update-form-content">
            <div className="health-update-form-section">
              <div className="health-update-form-section-select-group">
                <label className="health-update-form-section-select-group__label">
                  Mudar seguradora? - {contrato.nome}
                </label>
                <select
                  className="health-update-form-section-select-group__select"
                  {...register('seguradora', { required: false })}
                >
                  <option value="">Selecione</option>
                  {seguradoras.map((seguradora, index) => {
                    return (
                      <option
                        key={index}
                        value={seguradora.produto_seguradora_id}
                      >
                        {seguradora.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="health-update-form-section-input-group">
                <label className="health-update-form-section-input-group__label">
                  Número da Apólice
                </label>
                <input
                  className="health-update-form-section-input-group__input"
                  type="text"
                  placeholder="Digite aqui o Número da Apólice  ..."
                  {...register('numero-apolice', { required: false })}
                  onChange={() => console.log(control._formValues)}
                />
              </div>

              <div className="health-update-form-section-date-picker-group">
                <label className="health-update-form-section-date-picker-group__label">
                  Vigência Inicial
                </label>
                {errors.vigenciaInicial && (
                  <span className="health-update-form-section-remove">
                    Campo obrigatório
                  </span>
                )}
                <Controller
                  name="vigenciaInicial"
                  control={control}
                  defaultValue={
                    contrato.vigencia_inicial &&
                    new Date(contrato.vigencia_inicial)
                  }
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      className="health-update-form-section-date-picker-group__date-picker"
                      selected={value}
                      locale="ptBR"
                      onChange={onChange}
                      placeholderText="Selecione uma data"
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </div>

              <div className="health-update-form-section-select-group">
                <label className="health-update-form-section-select-group__label">
                  Mês de Aniversário
                </label>
                <select
                  className="health-update-form-section-select-group__select"
                  defaultValue={
                    contrato.mes_aniversario && contrato.mes_aniversario
                  }
                  {...register('mesAniversario', { required: false })}
                >
                  <option value="">Selecione</option>
                  {[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="health-update-form-section-textarea-group">
                <label className="health-update-form-section-textarea-group__label">
                  Multa Contratual de Recisão
                </label>
                <textarea
                  className="health-update-form-section-textarea-group__textarea"
                  type="text"
                  rows="2"
                  placeholder="Digite aqui a Multa Contratual de Recisão..."
                  {...register('multa-details', { required: false })}
                  onChange={() => console.log(control._formValues)}
                />
              </div>
            </div>

            <div className="health-update-form-section">
              <div className="health-update-form-section-checkbox-group">
                <label className="health-update-form-section-checkbox-group__label">
                  Precificação
                </label>
                <div className="health-update-form-section-checkbox-group__options">
                  <label
                    htmlFor="customedio"
                    className={
                      watch('tipoCusto') === 'Custo Médio'
                        ? 'health-update-form-section-checkbox-group__select-label health-update-form-section-checkbox-group__select-label--active'
                        : 'health-update-form-section-checkbox-group__select-label'
                    }
                  >
                    Custo Médio
                  </label>
                  <input
                    id="customedio"
                    type="radio"
                    value="Custo Médio"
                    {...register('tipoCusto', { required: false })}
                  />
                </div>
                <div className="health-update-form-section-checkbox-group__options">
                  <label
                    htmlFor="faixaetaria"
                    className={
                      watch('tipoCusto') === 'Faixa Etária'
                        ? 'health-update-form-section-checkbox-group__select-label health-update-form-section-checkbox-group__select-label--active'
                        : 'health-update-form-section-checkbox-group__select-label'
                    }
                  >
                    Faixa Etária
                  </label>
                  <input
                    id="faixaetaria"
                    type="radio"
                    value="Faixa Etária"
                    {...register('tipoCusto', { required: false })}
                  />
                </div>
                <div className="health-update-form-section-textarea-group">
                  <label className="health-update-form-section-textarea-group__label">
                    Forma de Custeio
                  </label>
                  <textarea
                    className="health-update-form-section-textarea-group__textarea"
                    type="text"
                    rows={7}
                    placeholder="Digite aqui os detalhes da Forma de Custeio..."
                    {...register('custeio-details', { required: false })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>
              </div>
              <div className="health-update-form-section-select-group">
                <label className="health-update-form-section-select-group__label">
                  Dia de vencimento da fatura
                </label>
                <select
                  className="health-update-form-section-select-group__select"
                  defaultValue={
                    contrato.vencimento_fatura && contrato.vencimento_fatura
                  }
                  {...register('vencimento', { required: false })}
                >
                  <option value="">Selecione</option>
                  {Array.from(Array(32).keys()).map((i) => {
                    if (i !== 0)
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    return null;
                  })}
                </select>
              </div>
            </div>

            {/*editar*/}
            <div className="health-update-form-section">
              <div className="health-update-form-section-checkbox-group">
                <label className="health-update-form-section-checkbox-group__label">
                  Coparticipação
                </label>
                <div className="health-update-form-section-checkbox-group__options">
                  <label
                    htmlFor="sim"
                    className={
                      watch('possui-coparticipacao') === 'sim'
                        ? 'health-update-form-section-checkbox-group__select-label health-update-form-section-checkbox-group__select-label--active'
                        : 'health-update-form-section-checkbox-group__select-label'
                    }
                  >
                    Sim
                  </label>
                  <input
                    id="sim"
                    type="radio"
                    value="sim"
                    {...register('possui-coparticipacao', { required: false })}
                  />
                </div>
                <div className="health-update-form-section-checkbox-group__options">
                  <label
                    htmlFor="nao"
                    className={
                      watch('possui-coparticipacao') === 'não'
                        ? 'health-update-form-section-checkbox-group__select-label health-update-form-section-checkbox-group__select-label--active'
                        : 'health-update-form-section-checkbox-group__select-label'
                    }
                  >
                    Não
                  </label>
                  <input
                    id="nao"
                    type="radio"
                    value="não"
                    {...register('possui-coparticipacao', { required: false })}
                  />
                </div>
                <div className="health-update-form-section-textarea-group">
                  <label className="health-update-form-section-textarea-group__label">
                    Detalhes da Coparticipação
                  </label>
                  <textarea
                    type="text"
                    rows={7}
                    placeholder={
                      watch('possui-coparticipacao') === 'sim'
                        ? 'Digite aqui os detalhes da Coparticipação...'
                        : ''
                    }
                    className={
                      watch('possui-coparticipacao') === 'sim'
                        ? 'health-update-form-section-textarea-group__textarea health-update-form-section-textarea-group__textarea--active'
                        : 'health-update-form-section-textarea-group__textarea health-update-form-section-textarea-group__textarea--disabled'
                    }
                    disabled={
                      watch('possui-coparticipacao') === 'sim' ? false : true
                    }
                    {...register('coparticipacao-details', { required: false })}
                  />
                </div>
              </div>
              <div className="health-update-form-section-select-group">
                <label className="health-update-form-section-select-group__label">
                  Dia de corte
                </label>
                <select
                  className="health-update-form-section-select-group__select"
                  defaultValue={contrato.dia_corte && contrato.dia_corte}
                  {...register('diaCorte', { required: false })}
                >
                  <option value="">Selecione</option>
                  {Array.from(Array(32).keys()).map((i) => {
                    if (i !== 0)
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    return null;
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="health-update-form-plans">
            <div className="health-update-form-plans-title">
              <span className="health-update-form-plans-title__span">
                Inclusão de planos
              </span>
            </div>
            <div className="health-update-form-section-input-group">
              <label className="health-update-form-section-input-group__label">
                Adicione os planos que os beneficiários desse contrato poderão
                escolher:
              </label>
              {planosField?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="health-update-form-section-input-group"
                  >
                    {planosField[index].tipo ? (
                      <>
                        <div className="health-update-form-section-remove">
                          <input
                            type="text"
                            defaultValue={planosField[index].tipo}
                            className={
                              planosField[index].deleted
                                ? 'health-update-form-section-remove__input health-update-form-section-remove__input--transition health-update-form-section-remove__input--deactivated'
                                : 'health-update-form-section-remove__input'
                            }
                            name={`planos${index}.nome`}
                            {...register(`planos.${index}.tipo`, {
                              required: false
                            })}
                          />
                          {user?.email ===
                            'rodrigo.pedroni@innoaseguros.com.br' && (
                            <div className="health-update-form-section-remove__icon">
                              {!planosField[index]?.deleted ? (
                                <button
                                  className="health-update-form-section-remove__button-icon"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <HiLightBulb
                                    className="health-update-form-section-remove__bulb-icon health-update-form-section-remove__button--activated"
                                    onClick={() =>
                                      openPopperUpdatePlan(
                                        planosField[index],
                                        index,
                                        'inativar'
                                      )
                                    }
                                  />
                                </button>
                              ) : (
                                <button
                                  className="health-update-form-section-remove__button-icon"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <HiLightBulb
                                    className="health-update-form-section-remove__bulb-icon health-update-form-section-remove__button--deactivated"
                                    onClick={() =>
                                      openPopperUpdatePlan(
                                        planosField[index],
                                        index,
                                        'ativar'
                                      )
                                    }
                                  />
                                </button>
                              )}
                              <button
                                className="health-update-form-section-remove__button-icon"
                                onClick={(e) => e.preventDefault()}
                              >
                                <HiTrash
                                  className="health-update-form-section-remove__bulb-icon health-update-form-section-remove__icon-trash"
                                  onClick={() =>
                                    openPopperUpdatePlan(
                                      planosField[index],
                                      index,
                                      'remover'
                                    )
                                  }
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="health-update-form-section-remove">
                          {' '}
                          {planosField.length > 0 && !planosField[index].tipo && (
                            <button
                              className="health-update-form-section-remove__button"
                              type="button"
                              onClick={() => planosRemove(index)}
                            >
                              remover
                            </button>
                          )}
                        </div>
                        <div>
                          <input
                            className="health-update-form-section-remove-input"
                            type="text"
                            placeholder="Ex.: TNQQ"
                            name={`planos${index}.nome`}
                            {...register(`planos.${index}.nome`, {
                              required: false
                            })}
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              <div className="health-update-form-section-button-group">
                <button
                  className="health-update-form-section-button-group__button"
                  type="button"
                  onClick={() => planosAppend({ nome: '' })}
                >
                  + Adicionar mais um plano.
                </button>
              </div>
            </div>
          </div>
          <div className="health-update-form-section-button-group">
            <Button type="submit">
              <span>Atualizar</span>
            </Button>
          </div>
        </form>
      </div>
      {openPopper && (
        <UpdatePopper
          setOpenPopper={setOpenPopper}
          selectedPlan={selectedPlan}
          deactivatePlan={deactivatePlan}
          activatePlan={activatePlan}
          removePlan={removePlan}
        />
      )}
    </>
  );
};

export default SeguroSaudeAtualizacao;
